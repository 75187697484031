<template>
    <div class="login_page d-flex jc-center ai-center">
        <img src="../../assets/images/logo2.png" alt="">
        <div class="text-center padding-bottom-lg color-white">
            <h2 class="padding-bottom-sm text-info-1">欢迎登录</h2>
            <h3 class="text-info-1">织物数字化管理大屏系统</h3>
        </div>
        <div class="form_box ">
            <dv-border-box-12>
                <div class="login_form ">
                    <h2 class="colorText text-center padding-bottom-lg text-info-1">用户登录</h2>
                    <el-form ref="formLogin" :model="loginForm" :rules="LoginRules" label-position="top"
                        label-width="120px" size="large">
                        <el-form-item prop="username">
                            <el-input v-model="loginForm.username" placeholder="请输入账号"
                                prefix-icon="el-icon-user-solid" />
                        </el-form-item>
                        <el-form-item prop="username">
                            <el-input v-model="loginForm.password" show-password placeholder="请输入密码"
                                prefix-icon="el-icon-lock" />
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox v-model="checked">记住密码</el-checkbox>
                        </el-form-item>
                        <el-form-item class="btn">
                            <el-button type="primary" style="width: 100%;" :loading="loading" class="fs-xl"
                                @click="handleLogin('formLogin')">登
                                录</el-button>
                        </el-form-item>
                    </el-form>
                </div>

            </dv-border-box-12>

        </div>
    </div>
</template>
<script>
import { setCache, getCache } from '../../utils';
import * as publicApi from '@/api/index.js'

export default {
    data() {
        return {
            checked: false,
            loading: false,
            loginForm: {
                username: '',
                password: ''
            },
            LoginRules: {
                username: [
                    { required: true, message: '请选择输入账号', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请选择输入密码', trigger: 'blur' }
                ],
            }
        }
    },
    watch: {
        checked: {
            handler(newv, oldv) {
                if (newv && this.loginForm.username != '' && this.loginForm.password != '') {
                    console.log(newv, 'new---------v')
                    //账号密码缓存22天
                    setCache('accountPsw', this.loginForm, 24 * 3600 * 22)
                }
            },
            deep: true
        }
    },
    methods: {
        handleLogin(formLogin) {
            this.loading = true
            this.$refs[formLogin].validate((valid) => {
                if (valid) {
                    publicApi.Login(this.loginForm).then(res => {

                        if (res.code == 0) {
                            console.log(res, 'res=-=---------')
                            res.data.userName = this.loginForm.username
                            localStorage.setItem('userInfo', JSON.stringify(res.data))
                            localStorage.setItem('Authorization', res.data.accessToken)
                            setTimeout(() => {
                                this.loading = false
                                this.$router.push({
                                    path: '/index',
                                    query: {
                                        customerId: res.data.customerId
                                    }
                                })
                            }, 1000);
                        } else {
                            this.loading = false

                        }
                    }).catch(err => {
                        console.log(err, 'err----------')
                    })

                } else {
                    console.log('error submit!!');
                    this.loading = false
                    return false;
                }
            });

        }

    },
    mounted() {
        console.error()
        let res = null
        if(getCache('accountPsw')){
            res = JSON.parse(getCache('accountPsw'))
        }
        if (res) {
            this.loginForm = {
                username: res.v.username,
                password: res.v.password,
            }
        }
        console.log(res, 'res--------')
    }
}
</script>
<style lang="scss" scoped>
.login_page {
    height: 100vh;
    width: 100vw;
    background: url('../../assets/images/login_bg.png') no-repeat;
    background-size: 100% 100%;
    flex-flow: column nowrap;

    ::v-deep .el-input__prefix {
        .el-input__icon {
            color: #00cdff;
        }
    }

    img {
        width: 500px;
    }

    .form_box {
        width: 660px;
        height: 400px;

    }

    .login_form {
        padding-top: 40px;
        width: 400px;
        margin: 0 auto;

        ::v-deep .el-input--prefix .el-input__inner {
            padding-left: 30px;
            background-color: rgba(17, 43, 73, 0.2);
            border-color: rgba(46, 96, 153, 0.7);
            color: #fff;
        }
    }
}
</style>